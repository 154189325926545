<template>
  <div>
    <div
      v-if="filters && filters.length"
      class="filter-home__root"
    >
      <div class="filter-home__filters">
        <template
          v-for="filter in $viewport.isLessThan('sm') ? shortenedFilters : filters"
          :key="filter.id"
        >
          <SelectFilterWithCheckboxTree
            v-if="filter.filterTemplate==='checkbox_tree'"
            :filter="filter"
            :selected-filters="selectedFiltersList as Record<string, object>"

            @onChangeFilterVariant="tapVariantMulti"
          />

          <SelectFilter
            v-if="filter.filterTemplate==='checkbox_list'"
            :filter="filter"
            :selected-filters="selectedFiltersList as Record<string, object>"
            @onChangeFilterVariant="tapVariantMulti"
          />

          <FilterButtonCheckboxList
            v-if="filter.filterTemplate.startsWith('checkbox_button_group') || filter.filterTemplate==='button_select'"
            :filter="filter"
            :selected-filters="selectedFiltersList as Record<string, object>"
            @onChangeFilterVariant="tapVariantMulti"
          />

          <FilterRange
            v-if="filter.filterTemplate === 'checkbox_list_with_range'"
            :filter="filter"
            :selected-filters="selectedFiltersList as Record<string, object>"
            @onChangeFilterVariant="tapVariantMulti"
          />
        </template>
      </div>

      <div
        v-if="!isHiddenSomeFilters || !$viewport.isLessThan('sm')"
        class="filter-home__footer"
      >
        <div class="catalog-filters__tags">
          <div
            v-for="category in checkBoxTagsList"
            :key="`cat-tag-btn-${category.name}`"
          >
            <div class="select-filter__title">{{ category.name }}</div>

            <div class="bottom-wrapper__lazy-filter">
              <div class="catalog-filter-tags">
                <FilterCheckboxTag
                  v-for="(tag, idx) in category.values"
                  :key="`filter-tags-${tag.id}-${idx}`"
                  :class="{'filter-tag-item_dark': tag.slug === SpecialFilterSlug.BLACK_FRIDAY}"
                  :filter="tag"
                  :selected-filters="selectedFiltersList as Record<string, object>"
                  @onChangeFilterVariant="tapVariantMulti"
                />
              </div>
            </div>
          </div>
          <div
            v-if="$viewport.isGreaterOrEquals('sm')"
            class="filter-footer__show"
          >
            <button
              class="filter-home__btn btn primary --shadow"
              @click="onSendFilter"
            >
              Показать {{ pagination.totalCount }} {{ $handleWordDeclension(products.length, buttonFilterWords()) }}
            </button>
            <div
              v-if="selectedFiltersList && Object.keys(selectedFiltersList).length"
              class="filter-clear"
              @click="onClearFilters"
            >
              <img
                src="~/assets/img/svg/common/close-black.svg"
                alt="close"
              >
              Сбросить фильтры
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="$viewport.isLessThan('sm')"
        class="mobile-filters-bottom"
      >
        <div
          v-if="isHiddenSomeFilters"
          class="bottom-btn --shadow filter-btn-more"
          @click="toggleHiddenFilters"
        >
          <img
            src="~/assets/img/svg/catalog/settings.svg"
            alt="close"
          >
          <span>Ещё фильтры</span>
        </div>
        <button
          class="filter-home__btn btn primary --shadow"
          @click="onSendFilter"
        >
          Показать {{ pagination.totalCount }} {{ $handleWordDeclension(products.length, buttonFilterWords()) }}
        </button>

        <div
          v-if="!isHiddenSomeFilters"
          class="bottom-btns"
        >
          <div
            class="bottom-btn --shadow"
            @click="onClearFilters"
          >
            <img
              src="~/assets/img/svg/catalog/close.svg"
              alt="close"
            >
            <span>Сбросить фильтры</span>
          </div>
          <div
            class="bottom-btn  --shadow"
            @click="toggleHiddenFilters"
          >
            <img
              src="~/assets/img/svg/catalog/rollup.svg"
              alt="roll"
            >
            <span>Свернуть фильтры</span>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <FilterLoader />
    </template>
  </div>
</template>

<script setup lang="ts">

import FilterButtonCheckboxList from '~/components/filters/FilterButtonCheckboxList/FilterButtonCheckboxList.vue';
import SelectFilter from '~/components/filters/SelectFilter.vue';
import FilterCheckboxTag from '~/components/filters/FilterCheckboxTag.vue';
import FilterRange from '~/components/filters/FilterRange.vue';
import useSitisCategory from '#sitis/composables/useSitisCategory';
import { useNuxtApp, onMounted, onBeforeUnmount } from '#imports';
import SelectFilterWithCheckboxTree from '~/components/filters/SelectFilterWithCheckboxTree.vue';
import FilterLoader from '~/components/loaders/FilterLoader.vue';
import { getFormattedCustomFiltersList } from '~/utils/filters/getFormattedCustomFiltersList';
import { SpecialFilterSlug } from "~/enums/specialFilterSlug";

export type FilterPricesType = {
	maxPrice: number;
	minPrice: number;
	selectedPrices: number[];
}

const props = defineProps<{
	url: string;
	slug: string;
	productType: number;
	categoryId: number;
}>();

const $viewport = useViewport();
const { $handleWordDeclension } = useNuxtApp();
const slugArray = props.slug.split('/');

const filterCategorySlug = ref<string>(slugArray.slice(-1).toString());
const defaultBasePathApi = ref<string>(slugArray.slice(0, -1).join('/'));
const {
  state,
  products,
  filters,
  pagination,

  onClearProductsList,
  getProducts,
  changeSelectedFilterFull,
  initSelectedFilter,
  getFilters,
  resetSelectedFilter
} = useSitisCategory({
  categorySlug: filterCategorySlug.value,
  defaultBasePathApi: defaultBasePathApi.value,
  extensions: ['characteristics', 'options', 'modifications'],
  isCancelApplyFilterToURL: true
});

const selectedFiltersList = ref<Record<string, object> | null>({});

const tapVariantMulti = async (filterSlug: string, variantSlugs: Record<string, string> | null, isDisabled?: boolean) => {
  if (isDisabled) {
    return null;
  }

  // Изменение текущего фильтра
  const filterSelected = { ...state.value.selectedFilter as Record<string, object> };
  if (filterSelected) {
    filterSelected[filterSlug] = variantSlugs || {};

    if (variantSlugs === null || Object.keys(variantSlugs).length <= 0) {
      delete filterSelected[filterSlug];

      if (selectedFiltersList.value) {
        delete selectedFiltersList.value[filterSlug];
      }
    }
  }

  selectedFiltersList.value = { ...selectedFiltersList.value, ...filterSelected };

  changeSelectedFilterFull(selectedFiltersList.value);
  await getProducts();
};

const checkBoxTagsList = computed(() => {
  const newTagsList = filters.value.filter((t) => t.filterTemplate.includes('checkbox_custom_button_group'));

  return getFormattedCustomFiltersList(newTagsList);
});

const initLocalSelectedFilters = () => {
  const initCategoryId = props.categoryId;

  if (typeof initCategoryId !== 'number' && !initCategoryId) {
    return;
  }
  const categories = filters.value.find((t) => t.slug === 'categories') || null;
  if (!categories) {
    return;
  }

  const categoriesVariants = categories?.variants || [];
  const categoriesVariantsSelected = categoriesVariants.filter((t) => t?.parentId === initCategoryId);

  if (categoriesVariantsSelected.length <= 0) {
    return;
  }

  const newSlugsCategories: Record<string, string> = {};

  categoriesVariantsSelected.forEach((t) => {
    newSlugsCategories[t.slug] = t.slug;
  });

  selectedFiltersList.value = {
    ...selectedFiltersList.value,
    categories: newSlugsCategories
  };
};

const onSendFilter = () => {
  const filter: string[] = [];
  const pageUrl = props.url;

  if (selectedFiltersList && selectedFiltersList.value && Object.keys(selectedFiltersList.value).length) {
    Object.keys(selectedFiltersList.value).forEach((key) => {
      filter.push(`${key}=${Object.keys(selectedFiltersList.value![key]).join(',')}`);
    });

    navigateTo(`${pageUrl}/f/${filter.join('/')}`);

    return;
  }
  navigateTo(`${pageUrl}`);
};

const buttonFilterWords = () => {
  if (props.productType === 2) {
    return ['паркинг', 'паркинга', 'паркингов'];
  }
  if (props.productType === 3) {
    return ['кладовую', 'кладовых', 'кладовых'];
  }
  if (props.productType === 4) {
    return ['помещение', 'помещения', 'помещений'];
  }
  if (props.productType === 5) {
    return ['паркинг', 'паркинга', 'паркингов'];
  }
  return ['квартиру', 'квартиры', 'квартир'];
};

const shortenedFilters = computed(() => (isHiddenSomeFilters.value ? filters.value.slice(0, 4) : filters.value));

const isHiddenSomeFilters = ref<boolean>(true);

const toggleHiddenFilters = () => {
  isHiddenSomeFilters.value = !isHiddenSomeFilters.value;
};

const onClearFilters = async () => {
  selectedFiltersList.value = {};

  resetSelectedFilter();
  await getProducts();
};

onMounted(async () => {
  initSelectedFilter();
  await getFilters();

  initLocalSelectedFilters();

  selectedFiltersList.value = { ...state.value.selectedFilter as Record<string, object> };

  if (selectedFiltersList.value && Object.keys(selectedFiltersList.value).length) {
    changeSelectedFilterFull(selectedFiltersList.value);
    // await getFilters();
    await getProducts();

    return;
  }

  await getProducts();
});

onBeforeUnmount(() => {
  onClearProductsList();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.filter-clear {
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--primary);
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	position: absolute;
	bottom: -25px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;

	& > img {
		width: 12px;
		filter: invert(10%) sepia(37%) saturate(6985%) hue-rotate(259deg) brightness(88%) contrast(92%);
	}
}

.filter-home__root {
	padding: 20px;
	background: var(--primary-10);
	border-radius: 12px;
	border: 0.75px solid #ECE8F0;
}

.filter-home__btn {
	min-width: 245px;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 15.238px; /* 84.656% */
	letter-spacing: -0.36px;
}

.bottom-wrapper__lazy-filter {
	display: flex;
	justify-content: space-between;
	gap: 40px;
	align-items: flex-end;
}

.filter-home__filters {
	display: flex;
	flex-wrap: wrap;
	gap: 25px 15px;
	margin-bottom: 15px;

	& > div {
		width: auto;
		max-width: 300px;
		min-width: 200px;
	}

	& > div:nth-child(1) {
		min-width: 250px;
		max-width: 250px;
	}
}

.filter-home__footer {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.catalog-filters__tags {
	display: flex;
	flex-wrap: wrap;
	gap: 40px;

	& > div {
		width: fit-content;
		max-width: 33%;
	}
}

.catalog-filter-tags {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.filter-footer__show {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: auto;
	margin-bottom: 0;
	position: relative;
}

@include media('lg') {
	.filter-home__filters {
		& > div {
			width: 100%;
			max-width: initial;
			min-width: 200px;
		}

    & > div:nth-child(1),
    & > div:nth-child(2) {
      width: 100%;
    }

    & > div:nth-child(1) {
      width: 250px;
    }

		& > div:nth-child(2) {
			width: auto;
		}
	}

	.filter-home__root {
		gap: 20px;
		padding: 24px 12px;
	}

	.filter-home__btn {
		width: 100%;
	}

	.filter-home__footer {
		flex-direction: column;
		gap: 10px;
	}

	.catalog-filters__tags {
		flex-direction: column;
		margin-top: 0;
		margin-left: 0;
		gap: 30px;

		& > div {
			max-width: initial;
			width: 100%;
			margin-top: 0;
			margin-left: 0;
		}
	}

  .filter-footer__show {
    width: 100%;
  }

  .bottom-wrapper__lazy-filter {
    flex-direction: column;
    align-items: flex-start;
  }
}

@include media('md') {
  .filter-home__filters {
    & > div:nth-child(1) {
      width: 100%;
    }
  }
}

.bottom-btns {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	gap: 7px;
	margin-top: 24px;
}

.bottom-btn {
	display: flex;
	align-items: center;
	padding: 10px 25px;
	justify-content: center;
	border-radius: 48px;
	border: 1px solid var(--primary-20);
	gap: 8px;

	& > span {
		color: var(--primary);
		font-size: 11px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 11px */
		letter-spacing: -0.165px;
		display: inline-block;
		width: min-content;
	}
}

.filter-home__footer {
	margin: 30px 0;
}

.filter-btn-more {
	max-width: 140px;
	width: 100%;
	padding: 8px 14px;
	justify-content: center;
	text-align: center;
	margin-bottom: 18px;

	& > span {
		width: fit-content;
	}

	& > img {
		width: 14px;
		height: 14px;
		filter: invert(64%) sepia(83%) saturate(448%) hue-rotate(358deg) brightness(103%) contrast(105%);
	}
}

.mobile-filters-bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
}

//.catalog-filters__tags {
//  margin-bottom: 20px;
//}
</style>
